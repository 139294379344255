import WebServiceRequest from '../Api/WebServiceRequest'


class DeleteProperty extends WebServiceRequest {
    constructor(context) {
        super(context)
    }

    setParams(data) {
        super.setTag(`AcademyProperty/Delete?id=${data.id}`);
    }
}




class CreateProperty extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('AcademyProperty/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}


export {

    CreateProperty,
    DeleteProperty,
};
