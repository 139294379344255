<template>
  <b-overlay :show="loadingOverlay">
    <b-form v-if="academy" @submit.prevent>
      <b-modal
          id="modal-edit"
          centered
          ok-title="بروزرسانی قیمت"
          ok-only
          @ok.preventDefault()="UpdatePrice"
      >
        <b-row v-if="SelectedPrice!==null">
          <div class="col-md-12">
            <small>قیمت جدید</small>
            <input type="number" class="form-control" v-if="SelectedPrice" v-model="SelectedPrice.priceValue">
          </div>
          <div class="col-md-12 my-2">
            <small>تعداد جدید</small>
            <input type="number" class="form-control" v-if="SelectedPrice" v-model="SelectedPrice.quantity">
          </div>
        </b-row>


      </b-modal>
      <b-card>
        <b-row>

          <b-col md="4" cols="12">
            <b-form-group
                label="نام دوره"
                label-for="mc-first-name"
            >
              <b-form-input
                  v-model="academy.title"
                  id="mc-first-name"
                  placeholder="نام دوره"
              />
            </b-form-group>
          </b-col>
          <b-col  v-if="brands" md="4" cols="12">
            <b-form-group
                label="برند دوره"
                label-for="mc-city3"
            >
              <v-select
                  v-model="academy.brandId"
                  :options="brands"
                  label="title"
                  :reduce="title => title.brandId"
                  :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" cols="12">
            <b-form-group
                label="توضیحات کوتاه دوره"
                label-for="mc-city"
            >
              <b-form-input
                  v-model="academy.summary"
                  id="mc-city"
                  placeholder="توضیحات کوتاه دوره"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12 my-25">
            <label>جزئیات دوره : </label>
            <b-row>
              <b-col md="3" cols="12">
                <label>نام</label>
                <input v-model="academyProperty.name" type="text" class="form-control"
                       placeholder="مثال جنس">
              </b-col>
              <b-col md="3" cols="12">
                <label>مقدار</label>
                <input v-model="academyProperty.value" type="text" class="form-control"
                       placeholder="مثال کتان">
              </b-col>
              <b-col class="my-2" md="2">
                <b-button @click="addNewProperty" variant="primary">افزودن</b-button>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="mb-2">
            <div class="d-flex flex-wrap  align-items-center">
              <b-badge class="mx-25" v-for="item in academy.properties " :key="item.propertyId" variant="primary">
                <feather-icon
                    @click="deleteProperty(item.propertyId)"
                    icon="TrashIcon"
                    class="  text-danger cursor-pointer"
                />
                <span class="mx-1">{{ item.name }}:{{ item.value }}</span>
              </b-badge>

            </div>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
                label="تاریخ و زمان انتشار"
                label-for=""
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <input
                    :id="`start${1}`"
                    :state="errors.length > 0 ? false:null"
                    :value="createJalaliDate(academy.publishDate)"
                    class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                    placeholder="تاریخ و زمان انتشار"
                    type="text"
                />
              </validation-provider>
              <custom-date-picker
                  v-model="academy.publishDate"
                  :element="`start${1}`"
                  :min="new Date(Date.now())"
                  auto-submit
                  class=""
                  color="#5c6bc0"
                  display-format="jYYYY/jMM/jDD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  input-format="YYYY-MM-DD HH:mm"
                  type="datetime"
              ></custom-date-picker>
            </b-form-group>
          </b-col>
          <b-col v-if="academy.photos.length>0" class="my-3" cols="12">
            <div class="d-flex align-items-center justify-content-start  flex-wrap">
              <div v-for="(item,idx) in academy.photos" :key="idx" class="position-relative mx-2 bg-white rounded">
                <feather-icon @click="RemovePic(item,idx)" icon="TrashIcon" size="20"
                              class="text-danger position-absolute top-0 cursor-pointer"/>
                <img :src=" baseUrl+item.base64"
                     style="width: 10rem;height: 10rem;object-fit: contain" class="rounded" alt="">
              </div>

            </div>

          </b-col>
          <b-col cols="12" >
          <h2 class="mb-2">برچسب ها</h2>

          <b-badge
              v-if="academy.tags.length>0"
              v-for="(tag,idx) in academy.tags"
              :key="idx"
              variant="warning"
              class="p-1 cursor-pointer m-1"
          >
            <feather-icon
                @click="deleteTag(tag)"
                icon="XIcon"
                class="text-danger"

            />
            <span class="ml-1 pt-25" >
          {{ tag.value }}
        </span>
          </b-badge>

          <b-row class="mt-2">
            <b-col md="6" class="">
              <b-form-group
                  class="mb-2"
                  label="برچسب جدید"
                  label-for="tagIds"
              >
                <b-form-input
                    id="tagIds"
                    v-model="newTagValue"
                    placeholder="برچسب جدید"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-button
                  variant="warning"
                  class="col-12 col-md-2 mt-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="addTag"
              >
                افزودن
              </b-button>
            </b-col>
          </b-row>
        </b-col>

          <b-col cols="12">
            <b-form-group
                label="پیوست عکس"
                label-for="mc-country"
            >
              <b-form-file

                  accept=".jpg, .png, .gif"
                  placeholder="انتخاب عکس"
                  @input="makeBase64Pic"
                  ref="refInputEl"
                  drop-placeholder="Drop file here..."
                  multiple
              />
            </b-form-group>

          </b-col>

          <b-col v-if="academyPhotos.length>0" class="my-3" cols="12">
            <div class="d-flex align-items-center justify-content-start  flex-wrap">
              <div v-for="(item,idx) in academyPhotos.filter(e=> e.priority!==0)" :key="idx"
                   class="position-relative mx-2 bg-white rounded">
                <feather-icon @click="academyPhotos.splice(idx,1)" icon="TrashIcon" size="20"
                              class="text-danger position-absolute top-0 cursor-pointer"/>
                <img :src="item.blob" style="width: 10rem;height: 10rem;object-fit: contain" class="rounded" alt="">
              </div>

            </div>

          </b-col>
          <b-col cols="12">
            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="academy.prices"
                responsive
                :fields="myTableColumns"
                primary-key="id"
                show-empty
                bordered
                striped
                empty-text="موردی موجود نیست!"
            >

              <!-- Column: delete -->

              <template #cell(priceValue)="data">

                <small>{{ Intl.NumberFormat('fa-IR').format(data.item.priceValue) }} <small
                    class="Toman">تومان</small></small>
              </template>
              <template #cell(quantity)="data">

                <small>{{ data.item.quantity }} </small>
              </template>
              <template #cell(optionValues)="data">

                <small v-for="el in data.item.optionValues">
                            <span v-if="!el.value.includes('#')">
                                {{ el.value }}
                            </span>
                  <div v-else class="d-flex">
                    <div class="p-2 rounded-circle" :style="`background-color:${el.value}`"></div>
                  </div>

                </small>
              </template>
              <template #cell(Edit)="data">

                <div class="cursor-pointer d-flex flex-row"
                     v-b-modal.modal-edit
                     @click="SetSelectedPrice(data.item)"
                >
                  <feather-icon class="text-primary" icon="EditIcon" size="20"/>
                </div>

              </template>

            </b-table>
          </b-col>
          <b-col cols="12">
            <addPriceAcademy v-if="academy.variablePrices" :Variable="academy.variablePrices"
                             @SendPrice="CallPrices"></addPriceAcademy>
          </b-col>
          <b-col
              cols="12"
          >
            <b-form-group
                label="توضیحات"
                label-for="summary"
            >
                <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent" :defaultContent="academy.description"></ckEditorMain>
            </b-form-group>
          </b-col>
          <!-- submit and reset -->

        </b-row>

      </b-card>
      <b-card class="blog-edit-wrapper">
        <h2>سئو</h2>

        <b-form v-if="academy && academy.seoTitle" class="mt-2">
          <b-row>
            <b-col md="6" class="">
              <b-form-group
                  label="عنوان"
                  label-for="blog-edit-title"
                  class=""
              >
                <b-form-input
                    id="blog-edit-seoTitle"
                    v-model="academy.seoTitle"
                />
              </b-form-group>
              <span class=""
                    :class="{'text-danger':academy.seoTitle.length>60}">تعداد کاراکترهای مجاز <small>{{
                  academy.seoTitle.length
                }}</small><small
                  class="text-secondary">/</small><small class="text-secondary">60</small></span>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col cols="10">
                  <b-form-group
                      label="آدرس"
                      label-for="blog-edit-seourl"
                      class=""
                  >
                    <b-form-input
                        id="blog-edit-seourl"
                        v-model="allCharactersToDash"
                        v-on:focus="$event.target.select()"
                        ref="myInput"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-button
                      variant="warning"
                      class="mt-2 ml-2"
                      @click="copyText"
                  >
                    کپی
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <!-- Field:  description -->
            <b-col cols="12" class="mt-2">
              <b-form-group
                  label="توضیحات"
                  label-for="blog-content"
                  class="mb-2"
              >
                <b-form-textarea
                    v-model="academy.seoDescription"
                ></b-form-textarea>
              </b-form-group>
              <span class=""
                    :class="{'text-danger':academy.seoDescription.length>160}">تعداد کاراکترهای مجاز <small>{{
                  academy.seoDescription.length
                }}</small><small
                  class="text-secondary">/</small><small class="text-secondary">160</small></span>
            </b-col>

            <b-card class="mt-1 w-100">

              <h2>نحوه نمایش در گوگل</h2>

              <div class="col-md-12 my-3">
                <!--- serp Desktop --->
                <div class="serp-preview d-lg-flex p-3 d-none flex-column align-items-end">
                  <div class="serp-title">
                    <h4 style="color: rgb(26, 13, 171)!important">
                      {{ academy.seoTitle }}
                    </h4>
                  </div>
                  <div class="serp-url">
                    <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                      <span style="overflow-wrap: anywhere">{{ academy.seourl.replaceAll(' ', '-') }}/</span>
                      <span>
                             {{ 'https://enzamode.ir/academy/' }}
                        <span class="serp-arrow"></span>
                      </span>

                    </h5>
                  </div>
                  <div class="serp-description">
                    <h6 style="overflow-wrap: anywhere">
                      {{ academy.seoDescription }}
                    </h6>
                  </div>
                </div>
                <!--- serp Mobile --->
                <div class="serp-preview d-lg-none  p-3 d-flex flex-column align-items-end">
                  <div class="serp-title">
                    <h6>
                      {{ academy.seoTitle }}
                    </h6>
                  </div>
                  <div class="serp-url d-flex align-items-center flex-wrap">

                    <small>{{ academy.seourl.replaceAll(' ', '-') }}/</small>
                    <small>
                      {{ 'https://enzamode.ir/plaza/' }}
                      <small class="serp-arrow"></small>
                    </small>
                  </div>
                  <div class="serp-description">
                    <small>
                      {{ academy.seoDescription }}
                    </small>
                  </div>
                </div>
              </div>
            </b-card>


          </b-row>
        </b-form>
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="updateAcademy"
          >
            ثبت نهایی
          </b-button>

        </b-col>
      </b-card>
    </b-form>
  </b-overlay>

</template>


<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BBadge,
  BFormInput,
  BTable,
  BFormCheckbox,
  BForm,
  BButton,
  BMedia,
  BOverlay,
  BCard,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import {ValidationObserver, ValidationProvider} from "vee-validate";
import vSelect from 'vue-select'
import {required} from '@validations'
import addPriceAcademy from '@/views/components/addPriceAcademy.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {getUserData} from "@/auth/utils";
import {GetAcademyByIdForVendor, UpdateAcademyPrice, UpdateAcademy} from "@/libs/Api/academy";
import {CreateProperty, DeleteProperty} from "@/libs/Api/academyProperty";
import Helper from "@/libs/Helper";
import {TagCreateRequest, TagDeleteRequest} from "@/libs/Api/Blog";
import {GetAllBrand} from "@/libs/Api/brands";
import {DeleteMediaByPicURL} from "@/libs/Api/portfolio";
import ckEditorMain from "@/views/components/ckEditorMain";
export default {
  head() {
    return {
      title: 'ویرایش دوره'
    }
  },
  components: {
    ToastificationContent,
    BBadge,
    BTable,
    BRow,
    BCol,
    BOverlay,
    addPriceAcademy,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    ckEditorMain,
    vSelect,
    BMedia,
    BCard,
    BFormInput,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BButton,
  },
  data() {
    return {
      loadingOverlay: false,
      academyProperty: {
        name: '',
        value: ''
      },
      myTableColumns: [
        {
          key: 'priceId',
          label: 'شناسه'
        },
        {
          key: 'priceValue',
          label: 'قیمت'
        },
        {
          key: 'quantity',
          label: 'تعداد'
        },
        {
          key: 'optionValues',
          label: 'نام ویژگی'
        },
        {
          key: 'Edit',
          label: 'ویرایش'
        },
      ],
      baseUrl: Helper.baseUrl,
      base64ImageSrc: '',
      FinalPriceV: null,
      SinglePRiceV: null,
      brands:null,
      SingleQty: null,
      PType: null,
      academyPhotos: [],
      academyProperties: [],
      Properties: [],
      variablePrices: false,
      newTagValue:'',
      SelectedPrice: null,
      academy: null
    }
  },
  async mounted() {
    await this.GetAcademy();
    await this.getAllBrands();
  },
  computed: {
    allCharactersToDash: {
      get: function () {
        return this.academy.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.academy.seourl = newValue
          // Remove all characters that are NOT number
          this.academy.seourl = this.academy.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.academy.seourl = this.academy.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.academy.seourl === "") {
          this.academy.seourl = null;
        }
      },
    },
  },
  methods: {
    setEditorContent(content){
      this.academy.description = content
    },
    createJalaliDate(param) {
      if (param !== '') {

        return param.slice(11, 16) + ' - ' + new Date(param.slice(0, 10)).toLocaleDateString("fa-IR",);
      }
    },
    async getAllBrands(){
      let _this = this
      let getAllBrand = new GetAllBrand(_this)
      let data = {
        pageNumber: 0,
        search: '',
        count: 0,
      }
      getAllBrand.setParams(data)
      await getAllBrand.fetch(function (content) {
        _this.brands = content.data.requests

      }, function (error) {
        console.log(error)
      })
    },
    async addTag() {
      let _this = this;
      _this.showOverlay = true;
      let data = {
        isDeleted: false,
        tagId: 0,
        value:_this.newTagValue,
        objectId: _this.academy.academyId,
        type:2,
        createDate:new Date(Date.now())
      }

      let tagCreateRequest = new TagCreateRequest(_this);
      tagCreateRequest.setParams(data);
      await tagCreateRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `برچسب اضافه شد.`,
          },
        })
        _this.showOverlay = false;
        _this.GetAcademy();
        _this.newTagValue = ''
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async deleteTag(tag) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        id : tag.tagId,
      }

      let tagDeleteRequest = new TagDeleteRequest(_this);
      tagDeleteRequest.setParams(data);
      await tagDeleteRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `برچسب حذف شد.`,
          },
        })
        _this.showOverlay = false;
        _this.GetAcademy();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    copyText() {
      this.$refs.myInput.focus();
      document.execCommand('copy');
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `آدرس دوره کپی شد.`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    async UpdatePrice() {
      let _this = this
      let updateAcademyPrice = new UpdateAcademyPrice(_this)
      let data = {
        priceId: this.SelectedPrice.priceId,
        academyId: parseInt(this.SelectedPrice.academyId),
        optionValueId: null,
        vendorUserId: getUserData().userId,
        priceValue: this.SelectedPrice.priceValue,
        quantity: parseInt(this.SelectedPrice.quantity),
      }
      updateAcademyPrice.setData(data)
      await updateAcademyPrice.fetch((content) => {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `قیمت بروزرسانی شد`,
          },
        })
        _this.GetAcademy();
      }, (err) => {
        console.log(err)
      })
    },
    SetSelectedPrice(item) {
      this.SelectedPrice = JSON.parse(JSON.stringify(item))
    },
    async RemovePic(item, idx) {
      let _this = this
      _this.loadingOverlay = true
      let deleteMediaByPicURL = new DeleteMediaByPicURL(_this)
      deleteMediaByPicURL.setParams([item.base64.split('/')[3]])
      await  deleteMediaByPicURL.fetch((content)=>{
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عکس حذف شد`,
          },
        })
        _this.GetAcademy();
      },(err)=>{
        console.log(err)
      })
      _this.loadingOverlay = false

    },
    async updateAcademy() {
      let FinalPrice = []
      if (this.academy.variablePrices) {
        let academyIds = {
          academy: parseInt(this.$route.params.id)
        }
        if (this.FinalPriceV !== null) {
          this.FinalPriceV.forEach((item) => {
            let result = {...item, ...academyIds}
            FinalPrice.push(result)
          })
        }
      }
      this.academyPhotos.map((item) => {
        delete item.blob
      })

      this.$refs.editorContent.emitDataToParent()
      let _this = this
      _this.loadingOverlay = true

      let updateAcademy = new UpdateAcademy(_this)
      let data = {
        academyId: parseInt(_this.$route.params.id),
        title: this.academy.title,
        summary: this.academy.summary,
        createDate: this.academy.createDate,
        seourl: this.academy.seourl,
        brandId: this.academy.brandId,
        country: this.academy.country,
        seoTitle: this.academy.seoTitle,
        isTolerated: this.academy.isTolerated,
        seoDescription: this.academy.seoDescription,
        variablePrices: this.academy.variablePrices,
        description: this.academy.description,
        vendorUserId:this.academy.vendorUserId,
        expireDate: this.academy.expireDate,
        academyCategoryId: this.academy.academyCategoryId,
        prices: FinalPrice,
        photos: this.academyPhotos,
        isInStock:this.academy.isInStock,
        tags:this.academy.tags,
        publishDate:this.academy.publishDate
      }
      updateAcademy.setData(data)
      await updateAcademy.fetch((content) => {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `دوره بروزرسانی شد`,
          },
        })
        _this.$router.push('/pages/academy/AcademyList')
      }, (err) => {
        console.log(err)
      })
      _this.loadingOverlay = false

    },
    async addNewProperty() {
      let _this = this
      if (this.academyProperty.value === '' || this.academyProperty.name === '') {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CheckIcon',
            variant: 'danger',
            text: `لطفا یک مقدار وارد کنید`,
          },
        })
      } else {
        let createProperty = new CreateProperty(_this)
        let data = {
          academyId: parseInt(this.$route.params.id),
          name: this.academyProperty.name,
          value: this.academyProperty.value
        }
        createProperty.setData(data)
        await createProperty.fetch((content) => {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `ویژگی با موفقیت ساخته شد`,
            },
          })
          this.GetAcademy();
          this.academyProperty.value = ''
          this.academyProperty.name = ''
        }, (err) => {
          console.log(err)
        })

      }

    },
    async deleteProperty(id) {
      let _this = this
      let deleteProperty = new DeleteProperty(_this)
      let data = {
        id: id
      }
      deleteProperty.setParams(data)
      await deleteProperty.fetch((content) => {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `جزئیات با موفقیت حذف شد`,
          },
        })
        this.GetAcademy();
      }, (err) => {
        console.log(err)
      })


    },
    async GetAcademy() {
      let _this = this;
      _this.loadingOverlay = true
      let getAcademyByIdForVendor = new GetAcademyByIdForVendor(_this);
      let data = {
        academyId: parseInt(this.$route.params.id)
      }
      getAcademyByIdForVendor.setParams(data);
      await getAcademyByIdForVendor.fetch(function (content) {
        console.log(content)
        _this.academy = content
      }, function (error) {
        console.log(error)
      })
      _this.loadingOverlay = false

    },
    CallPrices(FinalPriceV, SinglePriceV, SingleQty, PType) {
      this.FinalPriceV = FinalPriceV
      this.SinglePRiceV = SinglePriceV
      this.SingleQty = SingleQty
      this.PType = PType
    },

    async makeBase64Pic() {
      const that = this
      const f = []
      let tmpImage = {
        blob: '',
        base64: '',
        priority: 2
      }
      if (this.$refs.refInputEl.files.length > 4 || this.academyPhotos.length > 4) {
        this.$toast.error('میتوانید تا 5 عکس را همزمان اضافه کنید')
      } else {
        Array.prototype.forEach.call(this.$refs.refInputEl.files, (element) => {
          if (element.size > 512000) {
            this.$toast.error('اندازه عکس نمیتواند بیشتر از 512 کیلوبایت باشد')
          } else {
            f.push(element)
          }
        })
        f.forEach((element) => {
          const reader = new FileReader()
          reader.onload = (function (theFile) {
            return function () {
              let binaryData = reader.result
              tmpImage.base64 = window.btoa(binaryData)
              tmpImage.blob = URL.createObjectURL(element)
              const clone = {...tmpImage}
              that.academyPhotos.push(clone)
              tmpImage.blob = ''
              tmpImage.priority = 2
              tmpImage.base64 = ''
            }
          })(f)
          reader.readAsBinaryString(element)
        })

      }
      this.$refs.refInputEl.reset()


    }


  },
  directives: {
    Ripple,
  },
}
</script>

